import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import Spinner from 'manage-tritag/components/loading/spinner'
import EnhancedTable from 'manage-tritag/components/table/matches-table'
import {
  Match,
  QueryParams,
  useAssignRefereeMutation,
  useLazyGetMatchesQuery,
} from 'manage-tritag/services/api/endpoints/matches'
import { useLazyGetCompetitionsQuery } from 'manage-tritag/services/api/endpoints/competitions'
import { noLimitParams } from 'manage-tritag/utils/string'
import { getFormattedDateYYMMDDAA } from 'manage-tritag/utils/date'
import { toast } from 'react-toastify'
import MatchesTableFilter from './matches-table-filter'

interface HeadCell {
  disablePadding: boolean
  id: keyof Match
  label: string
  numeric: boolean
}

const MatchesPage = () => {
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
    competition: '',
  })
  const [matchIds, setmatchIds] = useState<String[]>([])
  const [referee, setReferee] = useState('')
  const [trigger, result] = useLazyGetMatchesQuery()
  const [competitionTrigger, competitionResult] = useLazyGetCompetitionsQuery()
  const [assignRefereeMutation, { isLoading: assignLoading }] =
    useAssignRefereeMutation()
  const { isLoading, isFetching, data: matches } = result
  const { data: competitions, isLoading: competitionLoading } =
    competitionResult

  useEffect(() => {
    if (params.competition) {
      trigger(params)
    } else if (competitions && competitions.length) {
      trigger({ ...params, competition: competitions[0]._id })
    }
  }, [params, competitions])

  useEffect(() => {
    if (!competitionLoading) {
      if (competitions) {
        if (!competitions?.length) {
          toast.error('No Competition found')
        }
      }
    }
  }, [competitionLoading, competitions])

  useEffect(() => {
    competitionTrigger({ ...noLimitParams, currentSeason: true })
  }, [])

  const handleSelect = (id: any) => {
    if (!matchIds.includes(id)) {
      setmatchIds([id, ...matchIds])
    } else {
      setmatchIds(matchIds.filter(i => i !== id))
    }
  }

  const assignReferee = async () => {
    if (assignLoading) return
    if (matchIds.length && referee) {
      await assignRefereeMutation({
        matchIds,
        referee,
      })

      toast.success('Referee successfully assigned.')
    } else {
      alert('No match selected')
    }
  }

  const headCells: HeadCell[] = [
    {
      id: 'round',
      numeric: false,
      disablePadding: false,
      label: 'Round',
    },
    {
      id: 'homeTeam',
      numeric: false,
      disablePadding: false,
      label: 'Home team',
    },
    {
      id: 'awayTeam',
      numeric: false,
      disablePadding: false,
      label: 'Away team',
    },
    {
      id: 'time',
      numeric: false,
      disablePadding: false,
      label: 'Date / time',
    },
    {
      id: 'venue',
      numeric: false,
      disablePadding: false,
      label: 'Venue',
    },
    {
      id: 'fieldNo',
      numeric: false,
      disablePadding: false,
      label: 'Field no.',
    },
  ]
  const bodyCells = [
    'round',
    'homeTeam',
    'awayTeam',
    'time',
    'venue',
    'fieldNo',
  ]
  return (
    <Layout label1="Matches" url="/matches">
      {isLoading || isFetching ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        matches && (
          <>
            <MatchesTableFilter
              competitions={competitions!}
              setParams={setParams}
              params={params}
              assignReferee={assignReferee}
              setReferee={setReferee}
            />
            <EnhancedTable
              tableTitle="Teams"
              uniqueIdentifier="ttr_id"
              isSelectable
              handleSelect={handleSelect}
              rows={matches.map((match, index) => ({
                ...match,
                id: index,
                round:
                  match.round.category.toLowerCase() === 'regular season'
                    ? match.round.number
                    : match.matchDisplayName,
                name: 'A-Team',
                homeTeam: match.homeTeam ? match.homeTeam.name : 'None',
                awayTeam: match.awayTeam ? match.awayTeam.name : 'None',
                venue: match.venue ? match.venue.name : 'None',
                time: getFormattedDateYYMMDDAA(new Date(match.time)),
              }))}
              headCells={headCells}
              bodyCells={bodyCells}
            />
          </>
        )
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(MatchesPage)

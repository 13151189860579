import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  styled,
} from '@mui/material'
import { navigate } from 'gatsby'
import { getComparator, stableSort } from 'manage-tritag/utils/table'
import React, { PropsWithChildren, useState } from 'react'
import EnhancedTableHead from './enhanced-table-head'

interface HeadCell<Data> {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

interface EnhancedTableProps<Data> {
  tableTitle: string
  uniqueIdentifier: keyof Data
  headCells: HeadCell<Data>[]
  bodyCells: string[]
  rows: Data[]
  isSelectable?: boolean
  participant?: boolean
  handleSelect?: any
}

type Order = 'asc' | 'desc'

const StyledEnhancedTable = styled('div')`
  width: 100%;
`

const StyledPaper = styled(Paper)`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing(2)};
`

const StyledTable = styled(Table)`
  min-width: 750;
`

const EnhancedTable = <
  DataSource extends {
    id: number | string
    _id: number | string
    [key: string]: unknown
  },
>(
  props: PropsWithChildren<EnhancedTableProps<DataSource>>,
) => {
  const {
    rows,
    headCells,
    bodyCells,
    isSelectable,
    participant,
    handleSelect,
  } = props
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof DataSource>('id')
  const [selected, setSelected] = useState<(number | string)[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DataSource,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map(n => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: number | string,
  ) => {
    if (!participant) {
      if (isSelectable) {
        navigate(`${id}/edit`)
      } else {
        // TODO IMPROVE
        navigate(`${id}/edit`)
      }
    } else {
      navigate(`/participants/${id}/edit`)
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: number | string) => selected.indexOf(id) !== -1

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <StyledEnhancedTable>
      <StyledPaper>
        <TableContainer>
          <StyledTable>
            <EnhancedTableHead
              isSelectable={isSelectable}
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort<DataSource>(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: DataSource, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      // eslint-disable-next-line no-underscore-dangle

                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {isSelectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            // checked={isItemSelected}

                            onChange={() => handleSelect(row._id)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                      )}
                      {bodyCells.map(cell => (
                        <TableCell
                          key={cell}
                          sx={{ whiteSpace: 'pre' }}
                          onClick={(event: any) => handleClick(event, row._id)}
                        >
                          {row[cell] as string}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              {/* {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledPaper>
    </StyledEnhancedTable>
  )
}

export default EnhancedTable

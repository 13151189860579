import {
  styled,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { MouseEvent } from 'react'

interface HeadCell<Data> {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

interface EnhancedTableProps<Data> {
  headCells: HeadCell<Data>[]
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void
  order: 'asc' | 'desc'
  orderBy: keyof Data
  isSelectable: boolean | undefined
}

const VisuallyHidden = styled('span')`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`

const EnhancedTableHead = <DataSource extends unknown>(
  props: EnhancedTableProps<DataSource>,
) => {
  const { headCells, order, orderBy, onRequestSort, isSelectable } = props
  const createSortHandler =
    (property: keyof DataSource) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {isSelectable && <TableCell padding="checkbox" />}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id as number | string}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              sx={{ fontWeight: 'bold' }}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <VisuallyHidden>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </VisuallyHidden>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
